import React from "react";
import '../../styles/layout/footer.scss';
import LanguageSwitcher from "../../components/LanguageSwitcher";
import RoadMap from "./RoadMap";
// import AnimatedElements from "../../components/AnimatedElements";

import whiteLogo from '../../images/logo/white/Logo-H-131-White-90.svg';

const Footer = ({locale}) => (
    <footer className="Footer">
        {/*<AnimatedElements theme="4" />*/}
        <div className="container Footer-Container">
            <div className='Footer-Content'>
                <div className='Footer-Logo'>
                    <img src={whiteLogo} alt="Лого" />
                </div>
                <LanguageSwitcher theme="white"/>
            </div>
            <RoadMap locale={locale}/>
        </div>
    </footer>
);

export default Footer;
