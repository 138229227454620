import React from "react";
import { globalHistory } from "@reach/router";

import MobileNavbar from "./MobileNavbar";
import Navbar from "./Navbar";
import HeaderOnlineBank from "../../components/HeaderOnlineBank";
import LanguageSwitcher from "../../components/LanguageSwitcher";
import MenuButton from "../../components/MenuButton";
import RedLogo from "../../components/RedLogo";
import YandexMetrika from "../../components/YandexMetrika";
import { gridBreakpoints } from "../../grid/grid";

import '../../styles/layout/header.scss';


const Header = ({locale}) => {
    const [open, setOpen] = React.useState(false);

    const closeFn = React.useCallback(() => {
        if (open) {
            document.body.classList.remove('showMobileMenu');
            setOpen(false);
        }
    }, [open]);

    const openFn = React.useCallback(() => {
        if (!open) {
            document.body.classList.add('showMobileMenu');
            setOpen(true);
        }
    }, [open]);

    const toggle = React.useCallback(() => {
        if (open) {
            closeFn();
        } else {
            openFn();
        }
    }, [open, openFn, closeFn]);

    React.useEffect(() => {
        const onChangeSize = () => {
            if (window.innerWidth >= gridBreakpoints.lg && open) {
                toggle();
            }
        };
        window.addEventListener('resize', onChangeSize);
        return () => {
            window.removeEventListener('resize', onChangeSize);
        }
    }, [open, toggle]);

    React.useEffect(() => {
        const unSubscriberHistory = globalHistory.listen(({ location }) => {
            if (open) {
                toggle();
            }
        });
        return () => {
            unSubscriberHistory();
        }
    }, [open, toggle]);

    return (
        <React.Fragment>
            <header className="Header">
                <div className="container">
                    <div className="Header-Inner">
                        <div className="Header-Cell Header-Cell_logo">
                            <RedLogo />
                        </div>
                        <div className="Header-Cell Header-Cell_onlineBank">
                            <HeaderOnlineBank locale={locale} goto="https://online.131.ru"/>
                        </div>
                        <div className="Header-Cell Header-Cell_mobileMenuButton">
                            <MenuButton onClick={toggle} open={open} />
                        </div>
                        <div className="Header-Cell Header-Cell_navbar">
                            <Navbar locale={locale}/>
                        </div>
                        <div className="Header-Cell Header-Cell_languages">
                            <LanguageSwitcher />
                        </div>
                    </div>
                </div>
            </header>
            <MobileNavbar locale={locale}/>
            <YandexMetrika />
        </React.Fragment>
    )
};

export default Header
